import React, { Component } from "react";
import bodyparts from "./components/app_content/navigation_pages_content/images/bodyparts.jpg";
import profession from "./components/app_content/navigation_pages_content/images/pharmacist.jpg";
import homenouns_image from "./components/app_content/navigation_pages_content/images/homenouns.jpg";
import homeverbs_image from "./components/app_content/navigation_pages_content/images/homeverbs.jpg";
import animals_image from "./components/app_content/navigation_pages_content/images/animals.jpg";
import colors_image from "./components/app_content/navigation_pages_content/images/colors.jpg";
import family_image from "./components/app_content/navigation_pages_content/images/family.jpg";
import family_image_2 from "./components/app_content/navigation_pages_content/images/maame-grey.png";
import chores_image from "./components/app_content/navigation_pages_content/images/chores.jpg";
import medicalnouns_image from "./components/app_content/navigation_pages_content/images/medicine_welcome1.png";
import romance_image from "./components/app_content/navigation_pages_content/images/romance.jpg";
import market from "./components/app_content/navigation_pages_content/images/market.png";
import greetings_image from "./components/app_content/navigation_pages_content/images/greetings.jpg";
import welcome_textile from "./components/app_content/navigation_pages_content/images/welcome-textile.png";
import rubiks_image from "./components/app_content/navigation_pages_content/images/rubiks.png";
import farmer_image from "./components/app_content/navigation_pages_content/images/okuani.png";
import teaching_image from "./components/app_content/navigation_pages_content/images/mathteacher.png";
import at_school_2_image from "./components/app_content/navigation_pages_content/images/teacher.png";
import romance_2_image from "./components/app_content/navigation_pages_content/images/romance.jpg";

import ReactPixel from "react-facebook-pixel";
if (process.env.REACT_APP_DEV_PIPELINE_STAGE == "production") {
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_CODE, options);
  ReactPixel.pageView();
}

const GroupItem = ({ info, ctx }) => {
  const quiz_info = ctx.state.igbo_quiz_association_info[info];
  const url_route = quiz_info[0];
  const image = quiz_info[1];
  const name = quiz_info[2];
  const display = ctx.quiz_info(info);
  return ctx.props.returnQuiz(url_route, image, name, display);
};
const DiamondDisplay = (group_items, ctx) => {
  var top = "";
  var left = "";
  var right = "";
  var down = "";
  if (group_items.length > 0) {
    top = <GroupItem info={group_items[0]} ctx={ctx} />;
  }
  if (group_items.length > 1) {
    left = <GroupItem info={group_items[1]} ctx={ctx} />;
  }
  if (group_items.length > 2) {
    right = <GroupItem info={group_items[2]} ctx={ctx} />;
  }
  if (group_items.length > 3) {
    down = <GroupItem info={group_items[3]} ctx={ctx} />;
  }
  return (
    <label id="courserow">
      <label>{top}</label>
      <label>
        {left}
        {right}
      </label>
      <label>{down}</label>
    </label>
  );
};
const ListOfGroupItems = ({ group_items, ctx }) => {
  return <ul>{DiamondDisplay(group_items, ctx)}</ul>;
};
const ListOfGroups = ({ groups, ctx }) => {
  return (
    <ul>
      {groups.map((single_group) => (
        <ListOfGroupItems group_items={single_group} ctx={ctx} />
      ))}
    </ul>
  );
};
class IgboSkillTree extends Component {
  constructor(props) {
    super(props);
    this.returnIgboSkillTree = this.returnSkillTree.bind(this);
    this.getCompletionStats = this.getCompletionStats.bind(this);
    this.state = {
      igbo_lesson_display_groups: [
        ["family"],
        ["igbo_family_2_blended", "greetings_igbo_1"],
        ["igbo_artists_puzzle"],
        ["market_food_1_vocab"],
        ["home_nouns_1", "medical_nouns_1_vocab"],
        ["body_parts"],
        ["romance"],
        ["colors", "professions_2"],
        ["animals"],
        ["igbo_cities_puzzle"],
        ["professions", "home_verbs_1", "doing_chores"],
        ["romance_2"],
        ["greetings_vocab", "at_school_1", "colors_2"],
        ["at_school_3"],
        ["animals_2", "doing_chores_2"],
      ],
      igbo_quiz_association_info: {
        // key must match quiz name in
        // igbo_lesson_display_groups.
        igbo_artists_puzzle: [
          "igbo-artists-puzzle-quiz",
          rubiks_image,
          "Puzzle: Igbo Artists",
        ],
        igbo_cities_puzzle: [
          "igbo-cities-puzzle-quiz",
          rubiks_image,
          "Puzzle: Igbo Cities",
        ],
        medical_nouns_1_vocab: [
          "igbo-medicalnouns-quiz",
          medicalnouns_image,
          "Medical Nouns",
        ],
        igbo_family_2_blended: [
          "igbo-family-blended-quiz",
          family_image_2,
          "Family 2",
        ],
        greetings_igbo_1: [
          "igbo-greetings-blended-quiz",
          welcome_textile,
          "Greetings 1",
        ],

        professions_2: [
          "/igbo-professions-2-quiz",
          farmer_image,
          "Professions",
        ],

        market_food_1_vocab: ["igbo-marketfood-quiz", market, "Market Food"],

        home_nouns_1: [
          "igbo-course-homenouns_quiz",
          homenouns_image,
          "Home Nouns",
        ],
        professions: ["/igbo-professions-quiz", profession, "Professions 2"],
        home_verbs_1: [
          "igbo-course-homeverbs-quiz",
          homeverbs_image,
          "Home Verbs",
        ],

        greetings_vocab: [
          "igbo-course-greetings-quiz",
          greetings_image,
          "Greetings",
        ],

        romance: ["igbo-romance-quiz", romance_image, "Romance"],

        family: ["igbo-course-family-quiz", family_image, "Family"],

        animals: ["igbo-animals-quiz", animals_image, "Animals"],

        body_parts: ["igbo-bodyparts-quiz", bodyparts, "Body Parts"],

        doing_chores: ["chores_quiz", chores_image, "Doing Chores"],

        colors: ["igbo-colors_quiz", colors_image, "Colors"],
        romance_2: ["romance_2_quiz", romance_2_image, "Romance 2"],
        at_school_1: [
          "igbo-at-school-1-quiz",
          at_school_2_image,
          "Education 1",
        ],
        colors_2: ["igbo-colors-2-quiz", colors_image, "Colors 2"],
        at_school_3: ["igbo-at-school-3-quiz", teaching_image, "Education 2"],
        animals_2: ["igbo-animals-2-quiz", animals_image, "Animals"],
        doing_chores_2: ["igbo-chores-2quiz", chores_image, "Doing Chores 2"],
      },
      igbo_non_limited_quizzes: new Set([
        "family",
        "igbo_family_2_blended",
        "home_nouns_1",
      ]),
      // language_name : 'igbo' // this.props.language_name
      // skill tree state info
      igbo_playable_quizzes_set: new Set([
        "igbo_artists_puzzle",
        "igbo_cities_puzzle",
        "home_nouns_1",
        "medical_nouns_1_vocab",
        "professions_2",
        "market_food_1_vocab",
        "professions",
        // "home_verbs_1",
        "romance",
        // "greetings_vocab",
        "family",
        "igbo_family_2_blended",
        "greetings_igbo_1",
        "body_parts",
        "colors",
        // "romance",
        "animals",
        // "doing_chores"
      ]),
      lesson_stats: {},
    };
  }

  componentDidMount() {
    this.getCompletionStats(
      this.state.lesson_stats,
      this.state.igbo_playable_quizzes_set
    );
  }

  getCompletionStats(lesson_stats, quiz_names) {
    this.props
      .getCompletionStats(quiz_names)
      .then((newItems) => this.setState({ lesson_stats: newItems }));
  }

  quiz_info(quiz_name) {
    const in_tree = this.state.igbo_playable_quizzes_set.has(quiz_name);
    const stats = this.state.lesson_stats[quiz_name];
    const limited = !this.state.igbo_non_limited_quizzes.has(quiz_name);
    var quiz_info_arr = [quiz_name, in_tree, stats, limited];
    return quiz_info_arr;
  }

  returnSkillTree() {
    return (
      <div className="courselineditems">
        <ListOfGroups
          groups={this.state.igbo_lesson_display_groups}
          ctx={this}
        />
      </div>
    );
  }

  render() {
    return <div>{this.returnSkillTree()}</div>;
  }
}
export default IgboSkillTree;
